import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React, { FC, FormEventHandler, useCallback, useState } from 'react';
import Select from 'react-select';

import useWindowDimensions from '../../hooks/useWindowDimensions';

import 'react-datepicker/dist/react-datepicker.css';
import DatePicker, { registerLocale } from "react-datepicker";
import el from "date-fns/locale/el"; // the locale you want

import {Helmet} from "react-helmet";
import './Form.css';
import { nl } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';





type FormProps = {
    image_left: boolean,
    image: IGatsbyImageData,
    onSubmit: FormEventHandler<HTMLFormElement>,
    loading?: boolean
    status?: 'success' | 'error'
}

function handleClick(){
    window.gtag("event","conversion", {send_to: "AW-10968892853/zC65CL-5wd0DELWLsO4o"})
    gtag("event","conversion", {send_to: "AW-10968892853/zC65CL-5wd0DELWLsO4o"})
    console.log("conv1 sent")
}

const STATUS_MESSAGES: { [key: string]: JSX.Element } = {
    success: <p style={{ color: '#7aa43c' }}>Het is gelukt! Uw aanvraag is verstuurd!</p>,
    error: <p style={{ color: '#e03939' }}>Er is iets misgegaan. Probeer het opnieuw.</p>
}

const Form: FC<FormProps> = ({ image_left, image, onSubmit, loading, status }) => {
    const { t } = useTranslation('translation', { keyPrefix: 'contactPage' });
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const { width } = useWindowDimensions();
    registerLocale("nl", nl);
    const options = [
        { value: t('reis1'), label: t('reis1') },
        { value: t('reis2'), label: t('reis2') },
        { value: t('reis3'), label: t('reis3') },
        { value: t('reis4'), label: t('reis4') },
        { value: t('reis5'), label: t('reis5') },
        { value: t('reis6'), label: t('reis6') },
        { value: 'Cruise', label: 'Cruise' }
    ]



    if (image_left && width > 768) {
        return (
            <div className='meta-container' style={{ margin: '200px 0' }}>
            <Helmet>
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-10968892853"></script>
                <script></script>
                
            </Helmet>
                <div className={`meta-body reverse_row`}>
                    <div className='meta-image-container'>
                        <GatsbyImage className={`meta-image meta-image-left`} alt='' image={image}></GatsbyImage>
                    </div>
                    <div className='meta-content align_right '>
                        <div className='form_container' style={{ position: 'relative', margin: '-219px 0' }}>
                            <form name='inquiry' onSubmit={onSubmit}>
                                <h4 className='form_header_large'>{t('form1_header1')}</h4>
                                <Select name='selectedTrip' className='select_input' options={options} placeholder={t('selectTrip')} />
                                <h5 className='form_header_small'>{t('form1_header2')}</h5>
                                <input name='name' className='input_field' placeholder={t('fullName')} required></input>
                                <input name='email' className='input_field' placeholder={t('email')} required></input>
                                <input name='phone' className='input_field' placeholder={t('telefoon')} required></input>
                                <h5 className='form_header_small'>{t('form1_header3')}</h5>
                                <div className='row'  >
                                    <div className='column'>
                                    {t('vanaf')}
                                        <DatePicker locale={'nl'} name='from' customInput={<input className='input_field' />} selected={startDate} onChange={(date: Date) => setStartDate(date)} />
                                    </div>
                                    <div className='column'>
                                    {t('tot')}
                                        <DatePicker locale={'nl'} name='until' customInput={<input className='input_field' />} selected={endDate} onChange={(date: Date) => setEndDate(date)} />
                                    </div>
                                </div>
                                <h5 className='form_header_small'>{t('form1_header4')}</h5>
                                <textarea name='message' className='input_field_large' placeholder={t('typeMsg')}></textarea>
                                <br></br>
                                <div className='row'>
                                    <input name='checkbox' type={'checkbox'} required></input>
                                    <label htmlFor='checkbox' >{t('voorwaarden')}</label>
                                </div>
                                <br></br>

                                <p>{t('verplicht')}</p>
                                <div className='btn_container' style={{ justifyContent: 'start', flexDirection: 'column' }}>
                                    <button onClick={() => handleClick()} type='submit' style={{ margin: '13px 0', width: '25%' }} className='read_more_btn'>{loading !== undefined && loading ? 'Bezig...' : t('verstuur')}</button>
                                    {status ? STATUS_MESSAGES[status] : ''}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    else if (!image_left && width > 768) {
        return (

            <div className='meta-container' style={{ margin: '200px 0' }}>
                <div className={`meta-body`}>
                    <div className='meta-image-container'>
                        <GatsbyImage className={`meta-image`} alt='' image={image}></GatsbyImage>
                    </div>
                    <div className='meta-content align_left '>
                        <form onSubmit={onSubmit} name='contact' className='form_container' style={{ position: 'relative', margin: '-206px 0' }}>
                            <h4 className='form_header_large'>{t('contact')}</h4>
                            <h5 className='form_header_small'>{t('form1_header2')}</h5>
                            <input name='name' className='input_field' placeholder={t('fullName')} required></input>
                            <input name='email' className='input_field' placeholder={t('email')} required></input>
                            <input name='phone' className='input_field' placeholder={t('telefoon')} required></input>
                            <h5 className='form_header_small'>{t('message')}</h5>
                            <textarea required name='message' className='input_field_large' placeholder={t('typeMsg')}></textarea>
                            <br></br>
                            <div className='row'>
                                <input required name='checkbox' type={'checkbox'}></input>
                                <label htmlFor='checkbox' >{t('voorwaarden')}</label>
                            </div>
                            <br></br>
                            <p>{t('verplicht')}</p>
                            <div className='btn_container' style={{ justifyContent: 'start', flexDirection: 'column' }}>
                                <button onClick={() => handleClick()} style={{ margin: '13px 0', width: '25%' }} className='read_more_btn'>{loading !== undefined && loading ? 'Bezig...' : t('verstuur')}</button>
                                {status ? STATUS_MESSAGES[status] : ''}
                            </div>
                        </form>
                    </div>
                </div>
            </div>


        );
    }

    else if (image_left && width <= 768) {
        return (
            <div className='meta-content' style={{ backgroundColor: '#fff' }}>
                <form name='inquiry' onSubmit={onSubmit}>
                    <h4 className='form_header_large'>{t('form1_header1')}</h4>
                    <Select name='selectedTrip' className='select_input' options={options} placeholder={t('selectTrip')} />
                    <h5 className='form_header_small'>{t('form1_header2')}</h5>
                    <input name='name' className='input_field' placeholder={t('fullName')} required></input>
                    <input name='email' className='input_field' placeholder={t('email')} required></input>
                    <input name='phone' className='input_field' placeholder={t('telefoon')} required></input>
                    <h5 className='form_header_small'>{t('form1_header2')}</h5>
                    <div className='row' >
                        <div className='column datepicker_column'>
                            {t('vanaf')}
                            <DatePicker locale={'nl'} name='from' customInput={<input className='input_field' />} selected={startDate} onChange={(date: Date) => setStartDate(date)} />
                        </div>
                        <div className='column'>
                            {t('tot')}
                            <DatePicker locale={'nl'} name='until' customInput={<input className='input_field' />} selected={endDate} onChange={(date: Date) => setEndDate(date)} />
                        </div>
                    </div>
                    <h5 className='form_header_small'>{t('form1_header3')}</h5>
                    <textarea name='message' className='input_field_large' placeholder={t('typeMsg')}></textarea>
                    <br></br>

                    <div className='row'>
                        <input name='checkbox' type={'checkbox'} required></input>
                        <label htmlFor='checkbox' >{t('voorwaarden')}</label>
                    </div>
                    <br></br>

                    <p>{t('verplicht')}</p>
                    <div className='btn_container' style={{ justifyContent: 'start', flexDirection: 'column' }}>
                        <button onClick={() => handleClick()} type='submit' style={{ margin: '13px 0', width: '35%' }} className='read_more_btn'>{loading !== undefined && loading ? 'Bezig...' : t('verstuur')}</button>
                        {status ? STATUS_MESSAGES[status] : ''}
                    </div>
                </form>
            </div>
        )
    }

    else {
        return (
            <div className='meta-content' style={{ backgroundColor: '#fff' }}>
                <form name='contact' onSubmit={onSubmit}>
                    <h4 className='form_header_large'>{t('contact')}</h4>
                    <h5 className='form_header_small'>{t('form1_header2')}</h5>
                    <input name='name' className='input_field' placeholder={t('fullName')} required></input>
                    <input name='email' className='input_field' placeholder={t('email')} required></input>
                    <input name='phone' className='input_field' placeholder={t('telefoon')} required></input>
                    <h5 className='form_header_small'>{t('message')}</h5>
                    <textarea required name='message' className='input_field_large' placeholder={t('typeMsg')}></textarea>
                    <br></br>
                    <div className='row'>
                        <input name='checkbox' type={'checkbox'} required></input>
                        <label htmlFor='checkbox' >{t('voorwaarden')}</label>
                    </div>
                    <br></br>
                    <p>{t('verplicht')}</p>
                    <div className='btn_container' style={{ justifyContent: 'start', flexDirection: 'column' }}>
                        <button onClick={() => handleClick()} style={{ margin: '13px 0', width: '35%' }} className='read_more_btn'>{loading !== undefined && loading ? 'Bezig...' : t('verstuur')}</button>
                        {status ? STATUS_MESSAGES[status] : ''}
                    </div>
                </form>
            </div>
        )
    }




}

export default Form;